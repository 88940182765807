<template>
  <div class="container">
    <div class="levels__container">
      <LevelTable
        v-for="(factor, index) in factors"
        :key="factor.id"
        :factor="factor"
        :index="index"
        :isOpen="isLevelsPageOpen"
        @onAdd="openSubfactorsPage(factor, $event)"
      />
    </div>
    <PointsConfigModal />

    <LevelOptions
      :isOpen="isLevelsPageOpen"
      :subfactor="currentSubfactor"
      :factor="currentFactor"
      @close="closeSubfactorsPage"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

import PointsConfigModal from './PointsConfigModal.vue';
import LevelOptions from './LevelOptions.vue';
import LevelTable from './LevelTable.vue';

export default {
  components: {
    PointsConfigModal,
    LevelOptions,
    LevelTable,
  },

  data: () => ({
    currentSubfactor: null,
    currentFactor: null,
    isLevelsPageOpen: false,
  }),

  computed: {
    ...mapState('jobEvaluationProcess', {
      factors: (state) => state.factors,
      subfactors: (state) => state.subfactors,
      pointsMultiplier: (state) => state.progress.pointsMultiplier,
    }),

    ...mapGetters('jobEvaluationProcess', ['getSubfactorName']),
  },

  methods: {
    ...mapMutations('jobEvaluationProcess', ['setPercentagesCopyAsOriginal', 'setValues']),
    openSubfactorsPage(factor, subfactor) {
      this.currentFactor = factor;
      this.currentSubfactor = subfactor;
      this.isLevelsPageOpen = true;
    },

    closeSubfactorsPage() {
      this.currentFactor = null;
      this.currentSubfactor = null;
      this.isLevelsPageOpen = false;
    },
  },

  mounted() {
    this.setPercentagesCopyAsOriginal();
    this.setValues();
  },
};
</script>

<style lang="scss" scoped>
.container {
  overflow: auto;
  height: 100%;
}

.factor-container {
  padding: 1.25em;
  color: var(--font-color-500);
  font-weight: var(--medium);

  & + & {
    border-top: 1px solid #e8e8e8;
  }
}

.subfactor-header,
.factor-header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1em;
}

.subfactor-header {
  margin: 2em 0 1em;
}

.text-button {
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: var(--main-color-500);
  font-weight: 600;
}

.factor-text {
  padding: 0.25em;
  color: var(--main-color-600);
  background-color: var(--main-color-100);
  border-radius: 4px;
}

.subfactor-text {
  width: fit-content;
  padding: 0.25em;
  color: var(--success-color-600);
  background-color: var(--success-color-100);
  border-radius: 4px;
}
.number-container {
  flex: 0 0 50px;
  display: inline-flex;
  justify-content: flex-end;
  margin-left: auto;
  font-weight: var(--semi-bold);
}
.levels__container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1em;
  overflow-y: auto;
  padding-right: 0.5rem;
}
</style>
