<template>
  <div class="card" :class="{ selected }" @click="$emit('click')">
    <!-- <span class="number" v-if="selected">{{ index + 1 }}</span> -->
    <div class="text">
      <div class="content">
        <h5 :title="subfactor.name">{{ subfactor.name }}</h5>
        <p :title="subfactor.description">{{ subfactor.description }}</p>
      </div>
      <unicon width="22px" height="22px" name="angle-right"></unicon>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  components: {},
  data: () => ({
    isEditModalOpen: false,
  }),
  props: {
    index: Number,
    subfactor: Object,
  },

  computed: {
    ...mapState({
      factors: (state) => state.jobEvaluationProcess.factors,
    }),

    selected() {
      const factor = this.factors.find((f) => f.id === this.subfactor.factorId);

      if (!factor) return false;

      return !!factor.subfactors.find((sf) => sf.id === this.subfactor.id);
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.card {
  border: 1px solid var(--gray-color-300);
  padding: 0.7rem;
  border-radius: 5px;
  cursor: pointer;
  .text {
    display: flex;
    overflow-x: hidden;
    gap: 0.25em;
    text-overflow: ellipsis;
    white-space: nowrap;
    fill: var(--font-color-200);
    align-items: center;
    .content {
      display: flex;
      flex-direction: column;
      width: calc(100% - 22px);
    }
    p {
      width: 100%;
      color: var(--font-color-200);
      line-height: 140%;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }
  &:hover {
    background-color: var(--gray-color-100);
  }
  &.selected {
    border-color: var(--main-color-400);
    background-color: var(--main-color-000);
    .text {
      fill: var(--main-color-400);
    }
    h5 {
      color: var(--main-color-400);
    }
    p {
      color: var(--main-color-400);
    }
    &:hover {
      background-color: var(--main-color-100);
    }
  }
}
</style>
