<template>
  <label class="radio-button" :class="{ 'radio-button--checked': isChecked }">
    <input
      class="radio-button__input"
      type="radio"
      :checked="isChecked"
      :name="groupName"
      :value="value"
      @change="onChange"
    />
    <span class="radio-button__radio"></span>
    {{ label }}
  </label>
</template>

<script>
export default {
  model: {
    prop: 'picked',
    event: 'change',
  },

  props: {
    groupName: String,
    label: String,
    picked: [Number, String, Object],
    value: [Number, String, Object],
  },

  computed: {
    isChecked() {
      switch (typeof this.value) {
        case 'number':
          return this.value === this.picked;
        case 'string':
          return this.value === this.picked;
        case 'object':
          return this.areObjectsEqual(this.value, this.picked);
        default:
          return this.value === this.picked;
      }
    },
  },

  methods: {
    onChange() {
      this.$emit('change', this.value);
    },
    areObjectsEqual(obj1, obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      return keys1.every((key) => obj1[key] === obj2[key]);
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-button {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 1em;
  color: var(--font-color-600);
  font-weight: var(--medium);
  border: 1px solid var(--gray-color-300);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 300ms;

  &--checked {
    background-color: var(--main-color-100);
    border-color: var(--main-color-300);
  }

  &:not(&--checked):hover {
    border-color: var(--gray-color-600);
  }

  &__input {
    display: none;
  }

  &__radio {
    width: 25px;
    height: 25px;
    border: 3px solid var(--gray-color-300);
    border-radius: 100%;
    transition: border-width 300ms, border-color 300ms;

    .radio-button:hover &,
    .radio-button--checked & {
      border-width: 8px;
    }

    .radio-button--checked & {
      border-color: var(--main-color-500);
    }
  }
}
</style>
