<template>
  <transition name="slide-fade">
    <div
      v-if="isOpen"
      v-click-outside="close"
      class="side-modal__mask"
      :class="`side-modal__mask-${size}`"
    >
      <div class="modal__container">
        <div class="modal__title">
          <span v-if="$slots.title2">
            <icon-button label="categorías" icon="arrow-left" @click="$emit('back')" />
            <h4><slot name="title2"></slot></h4>
          </span>
          <h4 v-else-if="$slots.title"><slot name="title"></slot></h4>
          <button class="modal__close--button" type="button" @click="close">
            <unicon name="times" fill="#8B8A87" height="18px" width="18px"></unicon>
          </button>
        </div>
        <div class="modal__content"><slot name="content"></slot></div>
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from 'v-click-outside';
import IconButton from '@/components/buttons/IconButton.vue';

export default {
  components: { IconButton },

  props: {
    isOpen: Boolean,
    size: String,
  },

  data: () => ({}),

  directives: {
    clickOutside: vClickOutside.directive,
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.side-modal__mask {
  position: fixed;
  right: 0;
  top: 64.4px;
  width: 600px;
  height: calc(100% - 64.4px);
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: white;
  border-left: 1.8px solid var(--gray-color-300);
  // box-shadow: -2px 0 5px 1px rgba(0, 0, 0, 0.1);
  overflow-y: hidden;
  z-index: var(--index-2);
  &-md {
    width: 480px;
  }
}

.slide-fade {
  &-enter-active,
  &-leave-active {
    transition: transform 300ms, opacity 300ms;
  }

  &-enter,
  &-leave-to {
    transform: translateX(50%);
    opacity: 0;
  }
}

.modal__close--button {
  z-index: 1;
  width: fit-content;
  border-radius: 100%;
  background-color: var(--gray-color-100);
  padding: 0.1rem;
  margin: 0.46rem;
  &:hover {
    background-color: var(--gray-color-300);
  }
}

.modal__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.modal__container {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.modal__content {
  flex-grow: 1;
  overflow: auto;
}
</style>
