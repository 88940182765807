var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"table"},[_c('header',{staticClass:"table__header"},[_c('div',[_c('h5',[_vm._v(_vm._s(((_vm.index + 1) + ".")))]),_c('h5',[_vm._v(_vm._s(_vm.factor.name))])]),_c('h5',[_vm._v(_vm._s(((_vm.factor.percentage) + "%")))])]),_c('div',{staticClass:"table__content"},[_vm._l((_vm.factor.subfactors),function(subfactor){return _c('div',{key:subfactor.id,staticClass:"table__row"},[_c('span',[_vm._v(_vm._s(subfactor.name))]),_c('UnitInput',{attrs:{"unit":"%","name":subfactor.name,"rules":{
          required: true,
          between: { min: 1, max: _vm.factor.percentage },
          sumShouldEqual: {
            total: _vm.factor.percentage,
            values: _vm.factor.subfactors.map(function (sf) { return sf.percentage; }),
          },
        },"value":subfactor.percentage},on:{"input":function($event){return _vm.inputHandler(subfactor.id, $event)},"blur":function($event){return _vm.updateSelectedSubfactorsOrder(_vm.factor.id)}}})],1)}),_c('Button',{attrs:{"disabled":false,"size":"medium","variant":"secondary-text"},on:{"click":function($event){return _vm.$emit('onAdd')}}},[_c('unicon',{attrs:{"width":"18px","height":"18px","name":"plus","fill":"#B1B0AE"}}),_vm._v(" Agregar subfactor ")],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }