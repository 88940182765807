var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view__container"},[_c('div',{staticClass:"content__top"},[_c('div',[_c('Breadcrumbs',{attrs:{"views":[],"currentView":{ label: 'Valorización de puestos', icon: 'suitcase' }}}),_c('h2',[_vm._v("Puntuación")])],1),_c('div',{staticClass:"content__buttons"},[_c('Button',{attrs:{"size":"small"},on:{"click":_vm.openPeriodsModal}},[_c('unicon',{attrs:{"name":"plus","fill":"currentColor","width":"16px","height":"16px"}}),_vm._v(" Agregar periodo ")],1),_c('Button',{attrs:{"size":"small"},on:{"click":_vm.onReset}},[_c('unicon',{attrs:{"name":"redo","fill":"currentColor","width":"16px","height":"16px"}}),_vm._v(" Volver a empezar ")],1)],1)]),_c('div',[_c('Menu',{attrs:{"direction":"below","closeOnItemClick":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('filter-item',{attrs:{"filter":{
            id: 'periods',
            name: 'Periodo actual',
            type: 'select',
            options: _vm.createdPeriods,
          }}})]},proxy:true},{key:"options",fn:function(){return _vm._l((_vm.createdPeriods),function(period){return _c('menu-item',{key:period.id,on:{"click":function($event){return _vm.onPeriodSelect(period)}}},[_vm._v(" "+_vm._s(period.name)+" "),(period.active)?_c('unicon',{attrs:{"width":"16px","height":"16px","name":"check","fill":""}}):_vm._e()],1)})},proxy:true}])})],1),_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"step__cards__container"},[_c('div',{staticClass:"step__cards__header"},[_c('h5',[_vm._v("Proceso de Puntuación")]),_c('p',[_vm._v(" "+_vm._s((_vm.lastFinishedStep + " de " + (_vm.steps.length) + " pasos completados "))),_c('span',[_vm._v("·")]),_vm._v(" "+_vm._s(_vm.lastFinishedStep - _vm.steps.length === 0 ? 0 : 1)+" en progreso ")])]),_vm._l((_vm.steps),function(step,index){return _c('div',{key:step.name,staticClass:"step__card",class:{
          'step__card--done': _vm.lastFinishedStep > index,
          'step__card--unavailable': _vm.lastFinishedStep < index,
        }},[_c('div',{staticClass:"step__card__info"},[_c('unicon',{attrs:{"name":step.icon,"height":"18","width":"18"}}),_c('span',[_c('h5',[_vm._v(_vm._s(step.name))]),_c('p',[_vm._v(" "+_vm._s(step.description)+" ")])])],1),_c('div',{staticClass:"step__card__link"},[_c('p',{on:{"click":function($event){_vm.lastFinishedStep < index ? '' : _vm.goToStep(index + 1)}}},[_vm._v(" "+_vm._s(_vm.lastFinishedStep > index || index === _vm.steps.length - 1 ? 'Ver paso' : 'Iniciar paso')+" ")]),_c('unicon',{attrs:{"name":"arrow-circle-right","height":"18","width":"18"}})],1)])})],2)]),_c('ConfirmDialogue',{ref:"confirmDialogue"}),_c('periods-modal',{ref:"modal__periods"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content__info"},[_c('div',[_c('h5',[_vm._v("Sistema de Evaluación de Puntos por Factor:")]),_c('p',[_vm._v(" La Organización Internacional del Trabajo (OIT) señala que este método es el más objetivo y adecuado para valorizar los puestos de trabajo dado existen factores de evaluación comunes en cada grupo de trabajo. ")])])])}]

export { render, staticRenderFns }