<template>
  <div class="summary-screen">
    <!-- <div class="table-header">
      <span>Factores/Subfactores</span>
      <span class="number-container">Valor</span>
    </div> -->
    <div class="factor-cards__container">
      <div
        @click="selectedFactor = factor"
        :class="`factor-card ${selectedFactor.id === factor.id ? 'factor-card--selected' : ''}`"
        v-for="(factor, index) in factors"
        :key="factor.id"
      >
        <div>
          <h5>{{ index + 1 }}.</h5>
          <h5>{{ factor.name }}</h5>
        </div>

        <h5>
          {{ factor.value }}
          puntos
        </h5>
      </div>
    </div>

    <div class="table">
      <Accordion
        v-for="(subfactor, sfIndex) in selectedFactor.subfactors"
        :key="subfactor.id"
        :launchOpen="true"
      >
        <div class="subfactor-header" slot="title">
          <span class="subfactor-text">
            {{ `${sfIndex + 1}. ${subfactor.name}` }}
          </span>
          <div class="number-container">
            <span class="subfactor-text">
              {{ subfactor.value }}
            </span>
          </div>
        </div>

        <div slot="content" class="levels__container">
          <div v-for="(option, index) in subfactor.options" :key="index" class="level">
            <span>{{ index + 1 }}.</span>
            <span>{{ option.name }}</span>
            <span class="number-container">{{ option.value }}</span>
          </div>
        </div>
      </Accordion>

      <!-- <div class="table-footer">
        <span>Total</span>
        <span class="number-container">{{ 100 * pointsMultiplier }}</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Accordion from '@/components/Accordion.vue';
import { faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';

export default {
  components: { Accordion },

  data() {
    return {
      selectedFactor: '',
      faCircleChevronDown,
    };
  },

  computed: {
    ...mapState('jobEvaluationProcess', {
      factors: (state) => state.factors,
      pointsMultiplier: (state) => state.progress.pointsMultiplier,
    }),

    ...mapGetters('jobEvaluationProcess', ['getSubfactorName']),
  },

  mounted() {
    [this.selectedFactor] = this.factors;
  },
};
</script>

<style lang="scss" scoped>
.summary-screen {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.factor-cards__container {
  display: flex;
  gap: 1rem;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.factor-card {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
  border-radius: 5px;
  border: solid 1px var(--main-color-500);
  h5 {
    padding: 3px 6px;
    font-weight: 500;
    color: var(--main-color-500);
  }
  div {
    display: flex;
    align-items: center;
    h5:first-child {
      border-radius: 3px;
    }
  }
  &--selected {
    background-color: var(--main-color-500);
    h5 {
      color: white;
    }
  }
  &:hover:not(.factor-card--selected) {
    background-color: var(--gray-color-100);
  }
}

.table {
  position: relative;
  align-items: stretch;
  font-size: 0.8rem;
  border: 1px solid var(--gray-color-400);
  border-radius: 5px;
  overflow-y: auto;

  &-footer {
    bottom: 0;
    margin-top: auto;
    color: var(--font-color-600);
    font-weight: 600;
    background-color: white;
    border-top: 1px solid var(--gray-color-400);
    font-size: 0.9rem;
  }

  &-header,
  &-footer {
    position: sticky;
    display: flex;
    align-items: center;
    padding: 0.8rem 1rem 0.8rem 1rem;
    z-index: var(--index-1);
  }
}

.subfactor-header,
.factor-header {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1em;
}

.level {
  display: flex;
  gap: 1rem;
  text-align: justify;
  border-bottom: 1px solid var(--gray-color-400);
  padding: 1rem;
}

.number-container {
  flex: 0 0 50px;
  display: inline-flex;
  justify-content: flex-end;
  margin-left: auto;
  font-weight: var(--semi-bold);
}

.subfactor-text {
  width: fit-content;
  padding: 0.25em;
  color: var(--main-color-600);
  background-color: var(--main-color-100);
  border-radius: 4px;
}
</style>
