<template>
  <div class="factors-step">
    <div>
      <p>
        La ponderación de cada factor debe estar relacionado con su importancia para la organización
        de acuerdo a su estrategia, valores y visión organizacional
      </p>

      <AlertBox type="info">
        Recuerda que la suma de los porcentajes debe ser igual a 100.
      </AlertBox>

      <div v-for="(factor, index) in factors" :key="factor.id" class="factor">
        <div>
          <span>{{ `${index + 1}.` }}</span>
          <span>
            {{ factor.name }}:
            <span> {{ descriptions[factor.name] }} </span>
          </span>
        </div>
        <UnitInput
          unit="%"
          :name="factor.id"
          :rules="{
            required: true,
            between: { min: 1, max: 99 },
            sumShouldEqual: {
              total: 100,
              values: factors.map((f) => f.percentage),
            },
          }"
          :value="factor.percentage"
          @input="updateFactorPercentage({ id: factor.id, percentage: $event })"
          @blur="updateOrder"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import AlertBox from '@/components/AlertBox.vue';
import UnitInput from '@/components/custom/UnitInput.vue';

export default {
  name: 'Factors',

  components: { AlertBox, UnitInput },

  data: () => ({
    descriptions: {
      Calificaciones:
        'Se refiere a los conocimientos y habilidades necesarios para realizar un trabajo específico.',
      Esfuerzos:
        'Se refiere a la dificultad, la fatiga y la tensión involucradas en completar el trabajo específico.',
      Responsabilidades:
        'Se refiere a las tareas que afectan los objetivos de la empresa, como la rentabilidad, la solidez financiera, entre otros.',
      Condiciones:
        'Designa las condiciones ambientales y el clima psicológico en que se realiza el trabajo.',
    },
  }),

  computed: {
    ...mapState({
      about: (state) => state.company.about || {},
      factors: (state) => state.jobEvaluationProcess.factors,
    }),
  },

  methods: {
    ...mapMutations('jobEvaluationProcess', ['updateFactorPercentage', 'updateFactorsOrder']),

    updateOrder() {
      const factorsCopy = JSON.parse(JSON.stringify(this.factors));
      this.updateFactorsOrder(factorsCopy.sort((a, b) => b.percentage - a.percentage));
    },
  },
};
</script>

<style lang="scss" scoped>
.factors-step {
  height: 100%;
  overflow: auto;
}

.factor {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.75em;
  color: var(--font-color-600);
  font-weight: var(--medium);
  border: 1px solid var(--gray-color-300);
  border-radius: 0.5em;

  &:nth-child(odd) {
    background-color: var(--gray-color-100);
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    span {
    }
    span span {
      color: var(--font-color-600);
      font-size: 0.875rem;
      font-weight: var(--regular);
    }
  }

  & + & {
    margin-top: 1em;
  }

  .list__item:last-child & {
    padding: 0.75em 1em;
  }
}

.alert {
  margin: 1em 0;
}
</style>
