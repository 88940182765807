<template>
  <SideModal :isOpen="isOpen" @close="close" @back="currentView = 'subfactors'" size='md'>
    <template #title>{{ subfactor.name }}: Niveles</template>
    <template #content>
      <div class="subfactors__view">
        <div class="subfactor__container">
          <div class="levels-container">
            <draggable
              v-if="subfactor.options.length"
              handle=".handle"
              :value="subfactor.options"
              @input="updateOrder(factor.id, subfactor.id, $event)"
            >
              <div v-for="(option, index) in subfactor.options" :key="index" class="level">
                <span>{{ option.value }}</span>
                <span class="handle">
                  <unicon name="draggabledots" fill="currentColor" height="16px" width="16px" />
                </span>
                <Textarea
                  class="textarea"
                  rules="required"
                  :value="option.name"
                  @input="
                    updateOptionName({
                      factorId: factor.id,
                      subfactorId: subfactor.id,
                      index,
                      name: $event,
                    })
                  "
                />
                <icon-button
                  icon="minus-circle"
                  @click="removeOption(factor.id, subfactor.id, index)"
                  label="remover"
                />
              </div>
            </draggable>
          </div>
        </div>
        <div class="subfactor__button">
          <span class="divider"></span>
          <Button
            @click="addOption(factor.id, subfactor.id)"
            :disabled="false"
            size="medium"
            variant="secondary-text"
          >
            <unicon width="18px" height="18px" name="plus" fill="#B1B0AE"></unicon>
            Crear nivel
          </Button>
        </div>
      </div>
    </template>
  </SideModal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import draggable from 'vuedraggable';
import SideModal from '@/components/menu/SideModal.vue';
import Button from '@/components/buttons/Button.vue';
import IconButton from '@/components/buttons/IconButton.vue';
import Textarea from '@/components/custom/Textarea.vue';

export default {
  components: {
    SideModal,
    Button,
    Textarea,
    draggable,
    IconButton,
  },
  props: {
    isOpen: Boolean,
    factor: Object,
    subfactor: Object,
  },

  data: () => ({
    isSubfactorModalOpen: false,
    currentView: 'subfactors',
  }),

  methods: {
    ...mapMutations('jobEvaluationProcess', [
      'addOptionToSubfactor',
      'removeOptionFromSubfactor',
      'updateOptionName',
      'updateOptionsOrder',
      'recalculateOptionsValues',
    ]),
    close() {
      this.$emit('close');
    },

    addOption(factorId, subfactorId) {
      this.addOptionToSubfactor({ factorId, subfactorId });
      this.recalculateOptionsValues({ factorId, subfactorId });
    },

    updateOrder(factorId, subfactorId, options) {
      this.updateOptionsOrder({ factorId, subfactorId, options });
      this.recalculateOptionsValues({ factorId, subfactorId });
    },

    removeOption(factorId, subfactorId, index) {
      this.removeOptionFromSubfactor({ factorId, subfactorId, index });
      this.recalculateOptionsValues({ factorId, subfactorId });
    },
  },

  computed: {
    ...mapState({
      subfactors: (state) => state.jobEvaluationProcess.subfactors,
    }),
  },
};
</script>

<style lang="scss" scoped>
.subfactors__view {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;
}
.subfactor__container {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  padding-right: 0.5rem;
  flex-grow: 1;
  overflow: auto;
}

.subfactor__button {
  background-color: white;
  width: calc(100%);
  padding-top: 1rem;
  button {
    width: 100%;
    justify-content: left;
  }
  .divider {
    width: 100%;
    height: 1.5px;
    background-color: var(--gray-color-200);
    display: flex;
    margin-bottom: 0.375rem;
  }
}

.handle {
  color: var(--gray-color-700);
  cursor: grab;
  transition: color 300ms;

  &:hover {
    color: var(--main-color-500);
  }
}

.level {
  display: flex;
  align-items: center;
  gap: 0.425rem;
  position: relative;

  & + & {
    margin-top: 1em;
  }

  & > span:first-child {
    background-color: var(--danger-color-100);
    border-radius: 3px;
    padding: 3px 6px;
    font-size: 0.875rem;
    position: absolute;
    bottom: -.5rem;
    right: 1.5rem;
    z-index: var(--index-3)
  }
}

.levels-container .textarea {
  flex-grow: 1;
  min-height: 100px;
}
</style>
