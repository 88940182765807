<template>
  <ValidationProvider
    class="textarea"
    tag="label"
    v-slot="{ errors, required }"
    :name="name || label"
    :rules="rules"
    :vid="vid"
  >
    <span v-if="label" class="textarea__label">{{
      `${label || name} ${required ? '*' : ''}`
    }}</span>
    <textarea
      class="textarea__field"
      :class="{ invalid: errors.length }"
      :disabled="disabled"
      :id="id"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
    >
    </textarea>
    <span class="textarea__error-message" v-if="errors.length"> {{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'Textarea',

  components: { ValidationProvider },

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },

    id: {
      default: '',
      type: String,
    },

    label: {
      default: '',
      type: String,
    },

    name: String,

    placeholder: {
      default: '',
      type: String,
    },

    rules: [Object, String],

    value: {
      default: '',
      type: String,
    },

    vid: String,
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  display: flex;
  flex-flow: column;
  gap: 0.5em;
  min-height: 150px;

  &__label {
    color: var(--font-color-300);
    font-size: 0.875rem;
    font-weight: var(--medium);
  }

  &__field {
    flex-grow: 1;
    padding: 0.75em;
    color: var(--font-color-700);
    background-color: white;
    border: 1px solid var(--gray-color-500);
    border-radius: 0.25em;
    transition: border-color 300ms, box-shadow 300ms;

    &:disabled {
      color: var(--font-color-300);
      background-color: var(--gray-color-300);
      cursor: not-allowed;
    }

    &:focus {
      border-color: var(--main-color-300);
      box-shadow: 0 0 0 4px var(--main-color-100);
    }

    &.invalid {
      border-color: var(--danger-color-300);
    }
  }

  &__error-message {
    color: var(--danger-color-500);
    font-size: 0.75rem;
  }
}
</style>
