<template>
  <Modal size="sm" ref="modal__subfactor" @close="close">
    <template #title> Configuración de Puntos </template>
    <template #content>
      <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div class="subfactor__content">
            <h3>¿Desea trabajar con 1000 o 2000 puntos?</h3>
            <p>
              Si la empresa cuenta con menos de 100 puestos en la organización recomendamos trabajar
              con la opción de 1000 puntos. Si la empresa cuenta con más de100 puestos en la
              organización recomendamos trabajar con la opción de 2000 puntos. No olvides que los
              resultados de la puntuación dependerá de la estructura de la organización y sus
              niveles.
            </p>

            <div class="radio-buttons-container">
              <RadioButton
                groupName="pointsMultiplier"
                label="Trabajar con 1000 puntos"
                :picked="multiplier"
                :value="10"
                @change="multiplier = $event"
              />
              <RadioButton
                groupName="pointsMultiplier"
                label="Trabajar con 2000 puntos"
                :picked="multiplier"
                :value="20"
                @change="multiplier = $event"
              />
            </div>
          </div>
          <div class="modal__buttons">
            <LoadingSpinner v-if="isLoading" size="sm" />
            <Button :disabled="isLoading" variant="text" @click="close">Cancelar</Button>
            <Button type="submit" variant="primary" :disabled="isLoading || invalid"
              >Aceptar</Button
            >
          </div>
        </form>
      </validation-observer>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';

import Button from '@/components/buttons/Button.vue';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import Modal from '@/components/Modal.vue';
import RadioButton from '@/components/custom/RadioButton.vue';

export default {
  components: {
    Button,
    LoadingSpinner,
    Modal,
    RadioButton,
    ValidationObserver,
  },

  data: () => ({
    multiplier: 10,
    isLoading: false,
  }),

  computed: {
    ...mapState('jobEvaluationProcess', {
      isOpen: (state) => state.isPointsConfigModalOpen,
      pointsMultiplier: (state) => state.progress.pointsMultiplier,
    }),
  },

  watch: {
    isOpen(value) {
      if (value) {
        this.open();
        this.multiplier = this.pointsMultiplier;
      } else this.$refs.modal__subfactor.close();
    },
  },

  methods: {
    ...mapActions('jobEvaluationProcess', ['updatePointsMultiplier']),
    ...mapMutations('jobEvaluationProcess', {
      setIsOpen: 'setIsPointsConfigModalOpen',
    }),
    open() {
      this.$refs.modal__subfactor.open();
    },

    close() {
      this.setIsOpen(false);
    },

    async onSubmit() {
      try {
        this.isLoading = true;
        await this.updatePointsMultiplier(this.multiplier);
        this.close();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
p {
  margin-top: 1em;
  line-height: 140%;
}

.radio-buttons-container {
  display: grid;
  gap: 0.5em;
  margin-top: 2em;
}

.subfactor__content {
  padding: 1.5rem;
}
</style>
