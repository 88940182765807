var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"factors-step"},[_c('div',[_c('p',[_vm._v(" La ponderación de cada factor debe estar relacionado con su importancia para la organización de acuerdo a su estrategia, valores y visión organizacional ")]),_c('AlertBox',{attrs:{"type":"info"}},[_vm._v(" Recuerda que la suma de los porcentajes debe ser igual a 100. ")]),_vm._l((_vm.factors),function(factor,index){return _c('div',{key:factor.id,staticClass:"factor"},[_c('div',[_c('span',[_vm._v(_vm._s(((index + 1) + ".")))]),_c('span',[_vm._v(" "+_vm._s(factor.name)+": "),_c('span',[_vm._v(" "+_vm._s(_vm.descriptions[factor.name])+" ")])])]),_c('UnitInput',{attrs:{"unit":"%","name":factor.id,"rules":{
          required: true,
          between: { min: 1, max: 99 },
          sumShouldEqual: {
            total: 100,
            values: _vm.factors.map(function (f) { return f.percentage; }),
          },
        },"value":factor.percentage},on:{"input":function($event){return _vm.updateFactorPercentage({ id: factor.id, percentage: $event })},"blur":_vm.updateOrder}})],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }