<template>
  <div class="subfactor-view__container">
    <div class="subfactor__content">
      <div class="title-bar">
        <h3>{{ subfactor.name }}</h3>

        <Menu direction="left">
          <template #label>
            <unicon
              class="ellipsis"
              name="ellipsis-v"
              fill="currentColor"
              height="16.5px"
              width="16.5px"
            />
          </template>
          <template #options>
            <menu-item @click="$emit('edit')">
              <unicon name="pen" fill="currentColor" height="16px" width="16px" />
              Editar subfactor
            </menu-item>
            <menu-item @click="onDelete" :isDanger="true">
              <unicon name="trash" fill="currentColor" height="16px" width="16px" />
              Eliminar subfactor
            </menu-item>
          </template>
        </Menu>
      </div>

      <p>{{ subfactor.description }}</p>
      <div class="table__container">
        <table v-if="subfactor.optionsExample && subfactor.optionsExample.length">
          <tr>
            <th>#</th>
            <th>Descripción</th>
          </tr>
          <tr v-for="(option, index) in subfactor.optionsExample" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ option }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="subfactor__buttons">
      <Button @click="toggleSubfactorSelection" type="submit" variant="secondary" size="small">
        <unicon
          :name="selected ? 'minus' : 'plus'"
          fill="currentColor"
          height="20px"
          width="20px"
        />
        {{ selected ? 'Quitar ' : 'Agregar ' }}subfactor
      </Button>
      <!-- <Button @click="$emit('edit')" type="submit" variant="secondary" size="small">
        <unicon name="pen" fill="currentColor" height="20px" width="20px" />
        Editar subfactor
      </Button>
      <Button type="submit" variant="danger" size="small" @click="onDelete">
        <unicon name="trash" fill="currentColor" height="20px" width="20px" />
        Eliminar subfactor
      </Button> -->
    </div>

    <!-- <template v-if="subfactor.optionsExample && subfactor.optionsExample.length">
      <span class="subtitle">Ejemplo de Niveles</span>
      <OptionsExampleTable :options="subfactor.optionsExample" />
    </template> -->
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import Button from '@/components/buttons/Button.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';

export default {
  components: {
    Button,
    Menu,
    MenuItem,
  },

  props: {
    isOpen: Boolean,
    subfactor: Object,
  },

  watch: {},

  computed: {
    ...mapState({
      factors: (state) => state.jobEvaluationProcess.factors,
    }),
    selected() {
      const factor = this.factors.find((f) => f.id === this.subfactor.factorId);

      if (!factor) return false;

      return !!factor.subfactors.find((sf) => sf.id === this.subfactor.id);
    },
  },

  methods: {
    ...mapMutations('jobEvaluationProcess', ['addSubfactorToFactor', 'removeSubfactorFromFactor']),
    ...mapActions('jobEvaluationProcess', ['deleteSubfactor']),
    async onDelete() {
      try {
        if (this.selected) this.toggleSubfactorSelection();
        await this.deleteSubfactor(this.subfactor.id);
        this.$emit('back');
      } catch (error) {
        console.log(error);
      }
    },

    toggleSubfactorSelection() {
      if (this.selected) {
        this.removeSubfactorFromFactor({
          factorId: this.subfactor.factorId,
          subfactorId: this.subfactor.id,
        });
      } else {
        this.addSubfactorToFactor({
          factorId: this.subfactor.factorId,
          subfactor: this.subfactor,
          options: this.subfactor.optionsExample || [],
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  margin-bottom: 0.5em;

  & .button {
    margin-left: auto;
  }
}

.subfactor-view__container {
  height: 100%;
  padding-right: 0.5rem;
  display: flex;
  flex-flow: column;
}

.subfactor__content {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.table__container {
  margin-top: 1rem;
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 0.1rem;
}

.subfactor__buttons {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  margin-top: 1rem;
  padding-bottom: 0.1rem;
}
.button {
  // width: 100%;
  flex-grow: 1;
  justify-content: left;
}
p {
  line-height: 1.2rem;
}

table {
  td:first-child,
  th:first-child {
    padding: 1rem;
  }
  td {
    white-space: pre-wrap;
  }
}
</style>
