<template>
  <div class="view__container">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[]"
          :currentView="{ label: 'Valorización de puestos', icon: 'suitcase' }"
        />
        <h2>Puntuación</h2>
      </div>
      <div class="content__buttons">
        <Button @click="openPeriodsModal" size="small">
          <unicon name="plus" fill="currentColor" width="16px" height="16px" />
          Agregar periodo
        </Button>
        <Button @click="onReset" size="small">
          <unicon name="redo" fill="currentColor" width="16px" height="16px" />
          Volver a empezar
        </Button>
      </div>
    </div>

    <div>
      <Menu direction="below" :closeOnItemClick="false">
        <template #label>
          <filter-item
            :filter="{
              id: 'periods',
              name: 'Periodo actual',
              type: 'select',
              options: createdPeriods,
            }"
          />
        </template>
        <template #options>
          <menu-item
            v-for="period in createdPeriods"
            @click="onPeriodSelect(period)"
            :key="period.id"
          >
            {{ period.name }}
            <unicon v-if="period.active" width="16px" height="16px" name="check" fill=""></unicon>
          </menu-item>
        </template>
      </Menu>
    </div>
    <div class="content">
      <div class="content__info">
        <div>
          <h5>Sistema de Evaluaci&oacute;n de Puntos por Factor:</h5>
          <p>
            La Organización Internacional del Trabajo (OIT) señala que este método es el más
            objetivo y adecuado para valorizar los puestos de trabajo dado existen factores de
            evaluación comunes en cada grupo de trabajo.
          </p>
        </div>
        <!-- <div>
          <h5>&iexcl;Vamos Paso a Paso!</h5>
          <p>Para valorizar, debemos definir estos procesos:</p>
        </div> -->
      </div>

      <div class="step__cards__container">
        <div class="step__cards__header">
          <h5>Proceso de Puntuaci&oacute;n</h5>
          <p>
            {{ `${lastFinishedStep} de ${steps.length} pasos completados ` }}<span>·</span>
            {{ lastFinishedStep - steps.length === 0 ? 0 : 1 }} en progreso
          </p>
        </div>
        <div
          class="step__card"
          :class="{
            'step__card--done': lastFinishedStep > index,
            'step__card--unavailable': lastFinishedStep < index,
          }"
          v-for="(step, index) in steps"
          :key="step.name"
        >
          <div class="step__card__info">
            <unicon :name="step.icon" height="18" width="18"></unicon>
            <span>
              <h5>{{ step.name }}</h5>
              <p>
                {{ step.description }}
              </p>
            </span>
          </div>
          <div class="step__card__link">
            <p @click="lastFinishedStep < index ? '' : goToStep(index + 1)">
              {{
                lastFinishedStep > index || index === steps.length - 1 ? 'Ver paso' : 'Iniciar paso'
              }}
            </p>
            <unicon name="arrow-circle-right" height="18" width="18"></unicon>
          </div>
        </div>
      </div>
    </div>

    <ConfirmDialogue ref="confirmDialogue" />

    <periods-modal ref="modal__periods" />
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';
import Button from '@/components/buttons/Button.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import FilterItem from '@/components/filters/FilterItem.vue';
import PeriodsModal from './PeriodsModal.vue';

export default {
  components: {
    Breadcrumbs,
    ConfirmDialogue,
    Button,
    Menu,
    MenuItem,
    FilterItem,
    PeriodsModal,
  },
  name: 'Steps',

  computed: {
    ...mapState('jobEvaluationProcess', {
      steps: (state) => state.steps,
      periods: (state) => state.periods,
      progress: (state) => state.progress,
      lastFinishedStep: (state) => state.progress.lastFinishedStep,
      periodId: (state) => state.selectedPeriod.id,
    }),
  },

  data: () => ({
    createdPeriods: [],
  }),

  methods: {
    ...mapMutations('jobEvaluationProcess', ['goToStep', 'setSelectedPeriod']),
    ...mapActions('jobEvaluationProcess', ['resetProgress', 'fetchFactors', 'fetchSubfactors']),

    openPeriodsModal() {
      this.$refs.modal__periods.open();
    },

    async onPeriodSelect(period) {
      this.createdPeriods = this.createdPeriods.map((item) => ({
        ...item,
        active: item.id === period.id,
      }));
      this.setSelectedPeriod(period);
      await this.fetchFactors();
      await this.fetchSubfactors();
    },

    async onReset() {
      try {
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Volver a empezar',
          message:
            '¿Estás seguro que deseas volver a empezar? Todo tu progreso será eliminado. Esta acción no se puede revertir.',
          okButtonText: 'Sí, estoy seguro',
        });

        if (!ok) return;

        await this.resetProgress();
      } catch (error) {
        console.log(error);
      }
    },
  },

  mounted() {
    if (this.periods && this.periodId) {
      this.createdPeriods = this.periods.map((period) => {
        const val = { active: period.id === this.periodId, ...period };
        return val;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.evaluation__content {
  padding: 0 1.5rem;
  height: calc(100%);
  overflow: auto;
}
.content__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.content__buttons {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}
.content {
  margin-top: 1rem;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  &__info {
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
    & > div:first-child * {
      display: inline;
    }
  }
}

.step__cards {
  &__container {
    border: solid 1px var(--gray-color-300);
    border-radius: 5px;
  }
  &__header {
    padding: 1rem;
    span {
      font-weight: var(--black);
    }
  }
}
.step__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  .unicon {
    fill: var(--font-color-700);
  }
  &:not(:first-child) {
    border-top: solid 1px var(--gray-color-300);
  }
  &__info {
    display: flex;
    gap: 1rem;
    align-items: center;
    p {
      line-height: 1rem;
    }
  }
  &__link {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    p {
      color: var(--main-color-500);
    }
    .unicon {
      fill: var(--main-color-500);
    }
  }
  &--unavailable &__link {
    cursor: not-allowed;
    p {
      color: var(--font-color-100);
    }
    .unicon {
      fill: var(--font-color-100);
    }
  }
}
</style>
