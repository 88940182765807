<template>
  <div v-if="hasFinishedSuccessfuly" class="success-screen">
    <div class="icon-container">
      <unicon
        name="check-circle"
        fill="currentColor"
        width="150px"
        height="150px"
      />
    </div>
    <h1>Puntuaci&oacute;n Guardada Exitosamente</h1>
    <Button variant="primary" @click="clickHandler">
      Empezar a Valorizar
      <unicon name="arrow-right" fill="currentColor" />
    </Button>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Button from '../../components/buttons/Button.vue';

export default {
  components: { Button },

  computed: {
    ...mapState('jobEvaluationProcess', {
      hasFinishedSuccessfuly: (state) => state.hasFinishedSuccessfuly,
    }),
  },

  methods: {
    ...mapMutations('jobEvaluationProcess', ['finishProcess']),

    clickHandler() {
      this.$router.push('/valorizacion-de-puestos/valorizacion');
      this.finishProcess();
    },
  },
};
</script>

<style scoped>
.success-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  place-items: center;
  gap: 1em;
  background-color: white;
  z-index: var(--index-2);
}

.icon-container {
  color: var(--gray-color-700);
}
</style>
