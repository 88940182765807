var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',{staticClass:"loading-screen"},[_c('LoadingSpinner')],1):(_vm.currentStep === 0)?_c('InitialScreen'):_c('ValidationObserver',{staticClass:"job-evaluation-process-container",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('StepsHeader',{attrs:{"steps":_vm.steps,"currentStep":_vm.currentStep}}),_c('Header',{on:{"export":_vm.openExportModal}}),_c('div',{staticClass:"current-step-container"},[(_vm.currentStep === 1)?_c('FactorsStep'):(_vm.currentStep === 2)?_c('SubfactorsStep'):(_vm.currentStep === 3)?_c('Levels'):(_vm.currentStep === 4)?_c('SummaryScreen'):_vm._e()],1),_c('Footer',{attrs:{"invalid":invalid}}),_c('ConfirmDialogue',{ref:"confirmDialogue"}),_c('SuccessScreen'),_c('export-modal',{ref:"modal__export",attrs:{"formats":{
      excel: {
        id: 'excel',
        name: 'Excel',
        options: [
          {
            id: 'view',
            name: _vm.steps[_vm.currentStep - 1].name,
          } ].concat( (_vm.currentStep === 2
            ? [
                { id: 'condiciones', name: 'Condiciones' },
                { id: 'responsabilidades', name: 'Responsabilidades' },
                { id: 'calificaciones', name: 'Calificaciones' },
                { id: 'esfuerzos', name: 'Esfuerzos' } ]
            : []) ),
      },
    }},on:{"export":_vm.exportToExcel}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }