<template>
  <div class="factors-step" ref="container">
    <p>
      Identifica qu&eacute; factores tienen mayor relevancia con la misi&oacute;n, visi&oacute;n y
      objetivos de la organizaci&oacute;n y asigna un porcentaje a cada uno de ellos.
    </p>

    <div class="container">
      <ul class="cards-container">
        <FactorTable
          v-for="(factor, index) in factors"
          :key="factor.id"
          :factor="factor"
          :index="index"
          @onAdd="openSubfactorsPage(factor)"
        />
        <FactorSubfactors
          :isOpen="isSubfactorsPageOpen"
          :factor="currentFactor"
          @close="closeSubfactorsPage"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import FactorTable from './FactorTable.vue';
import FactorSubfactors from './FactorSubfactors.vue';

export default {
  name: 'SubfactorsStep',

  components: {
    FactorTable,
    FactorSubfactors,
  },

  data() {
    return {
      backgroundColors: [],
      currentFactor: null,
      isSubfactorsPageOpen: false,
      isSideModalOpen: false,
    };
  },

  computed: {
    ...mapState({
      about: (state) => state.company.about || {},
      factors: (state) => state.jobEvaluationProcess.factors,
    }),
  },

  methods: {
    ...mapMutations('jobEvaluationProcess', ['updateFactorPercentage', 'updateFactorsOrder']),
    openSubfactorsPage(factor) {
      this.currentFactor = factor;
      this.isSubfactorsPageOpen = true;
    },

    closeSubfactorsPage() {
      this.currentFactor = null;
      this.isSubfactorsPageOpen = false;
    },

    updateOrder() {
      const factorsCopy = JSON.parse(JSON.stringify(this.factors));
      this.updateFactorsOrder(factorsCopy.sort((a, b) => b.percentage - a.percentage));
    },
  },

  mounted() {
    this.backgroundColors = [
      '--gray-color-600',
      '--gray-color-500',
      '--gray-color-200',
      '--gray-color-100',
    ].map((color) => getComputedStyle(this.$refs.container).getPropertyValue(color));
  },
};
</script>

<style lang="scss" scoped>
.factors-step {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;
  gap: 0.875rem;
}

.container {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 1rem;
  overflow: hidden;
  & > div {
    border-radius: 8px;
    &:first-child {
      display: grid;
      place-items: center;
    }
  }
}

.cards-container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1em;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;

  h1 {
    font-size: 2rem;
    font-weight: 600;
  }
}
</style>
