<template>
  <div v-if="isLoading" class="loading-screen">
    <LoadingSpinner />
  </div>

  <InitialScreen
    v-else-if="currentStep === 0"
  />

  <ValidationObserver v-else class="job-evaluation-process-container" v-slot="{ invalid }">
    <StepsHeader :steps="steps" :currentStep="currentStep" />

    <Header @export="openExportModal"/>

    <div class="current-step-container">
      <FactorsStep v-if="currentStep === 1" />
      <SubfactorsStep v-else-if="currentStep === 2" />
      <!-- <CalibrationStep v-else-if="currentStep === 3" /> -->
      <Levels v-else-if="currentStep === 3" />
      <SummaryScreen v-else-if="currentStep === 4" />
    </div>

    <Footer :invalid="invalid" />

    <ConfirmDialogue ref="confirmDialogue" />

    <SuccessScreen />

    <export-modal
      ref="modal__export"
      @export="exportToExcel"
      :formats="{
        excel: {
          id: 'excel',
          name: 'Excel',
          options: [
            {
              id: 'view',
              name: steps[currentStep - 1].name,
            },
            ...(currentStep === 2
              ? [
                  { id: 'condiciones', name: 'Condiciones' },
                  { id: 'responsabilidades', name: 'Responsabilidades' },
                  { id: 'calificaciones', name: 'Calificaciones' },
                  { id: 'esfuerzos', name: 'Esfuerzos' },
                ]
              : []),
          ],
        },
      }"
    />
  </ValidationObserver>
</template>

<script>
/* eslint-disable */
import { utils } from 'xlsx';
import * as XLSXS from 'xlsx-style';

import { ValidationObserver } from 'vee-validate';

import { mapActions, mapMutations, mapState } from 'vuex';

import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';
import StepsHeader from '@/components/StepsHeader.vue';
import ExportModal from '@/components/ExportModal.vue';
import Footer from './Footer.vue';
import InitialScreen from './InitialScreen.vue';
import FactorsStep from './factorStep/FactorsStep.vue';
import SubfactorsStep from './subfactorsStep/SubfactorsStep.vue';
// import CalibrationStep from './calibrationStep/CalibrationStep.vue';
import Levels from './levelsStep/LevelsStep.vue';
import Header from './Header.vue';
import SummaryScreen from './SummaryScreen.vue';
import SuccessScreen from './SuccessScreen.vue';

export default {
  components: {
    // CalibrationStep,
    FactorsStep,
    Footer,
    Header,
    InitialScreen,
    Levels,
    StepsHeader,
    SubfactorsStep,
    ValidationObserver,
    LoadingSpinner,
    ConfirmDialogue,
    SummaryScreen,
    SuccessScreen,
    ExportModal,
  },

  data() {
    return {
    };
  },

  computed: {
    ...mapState('jobEvaluationProcess', {
      currentStep: (state) => state.progress.currentStep,
      steps: (state) => state.steps,
      isLoading: (state) => state.isLoading,
      hasFinished: (state) => state.progress.hasFinished,
      pointsMultiplier: (state) => state.progress.pointsMultiplier,
      factors: (state) => state.factors,
      subfactors: (state) => state.subfactors,
      periods: (state) => state.periods,
      selectedPeriod: (state) => state.selectedPeriod,
    }),
  },

  methods: {
    ...mapActions('jobEvaluationProcess', ['fetchFactors', 'fetchSubfactors', 'fetchPeriods']),
    ...mapMutations('jobEvaluationProcess', ['setIsLoading', 'setProgress', 'setSelectedPeriod']),

    saveBlobAsFile(name, blob) {
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },

    s2ab(s) {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
    openExportModal() {
      this.$refs.modal__export.open();
    },
    exportFirstStep() {
      const evaluationMap = this.factors.map((factor, index) => ({
        'Nro.': index + 1,
        Factor: factor.name,
        Porcentaje: `${factor.percentage || 0}%`,
      }));

      evaluationMap.push({
        'Nro.': '',
        Factor: 'Total',
        Porcentaje: '100%',
      });

      return [evaluationMap, 'reporte_factores'];
    },

    exportSecondStep() {
      const evaluationMap = this.factors.reduce((acc, factor, factorIndex) => {
        const factorArray = [
          {
            'Nro.': `${factorIndex + 1}`,
            'Factor/Subfactor': factor.name,
            Porcentaje: `${factor.percentage || 0}%`,
          },
        ];

        if (factor.subfactors) {
          factor.subfactors.forEach((sf, sfIndex) => {
            factorArray.push({
              'Nro.': `${factorIndex + 1}.${sfIndex + 1}`,
              'Factor/Subfactor': sf.name,
              Porcentaje: `${sf.percentage || 0}%`,
            });
          });
        }

        return [...acc, ...factorArray];
      }, []);

      evaluationMap.push({
        'Nro.': '',
        'Factor/Subfactor': 'Total',
        Porcentaje: '100%',
      });

      return [evaluationMap, 'reporte_subfactores'];
    },

    exportThirdStep() {
      const evaluationMap = this.factors.reduce((acc, factor, factorIndex) => {
        const factorArray = [
          {
            'Nro.': `${factorIndex + 1}`,
            'Factor/Subfactor': factor.name,
            'Sin Calibración': `${factor.percentage || 0}%`,
            'Con Calibración': `${factor.percentageCopy || 0}%`,
          },
        ];

        if (factor.subfactors) {
          factor.subfactors.forEach((sf, sfIndex) => {
            factorArray.push({
              'Nro.': `${factorIndex + 1}.${sfIndex + 1}`,
              'Factor/Subfactor': sf.name,
              'Sin Calibración': `${sf.percentage || 0}%`,
              'Con Calibración': `${sf.percentageCopy || 0}%`,
            });
          });
        }

        return [...acc, ...factorArray];
      }, []);

      evaluationMap.push({
        'Nro.': '',
        'Factor/Subfactor': 'Total',
        'Sin Calibración': '100%',
        'Con Calibración': `${
          this.factors.reduce((acc, factor) => acc + factor.percentageCopy, 0) || 0
        }%`,
      });

      return [evaluationMap, 'reporte_calibracion'];
    },

    exportLastStep() {
      const evaluationMap = this.factors.reduce((acc, factor, factorIndex) => {
        const factorArray = [
          {
            'Nro.': `${factorIndex + 1}`,
            'Factor/Subfactor': factor.name,
            Valor: factor.value || 0,
          },
        ];

        if (factor.subfactors) {
          factor.subfactors.forEach((sf, sfIndex) => {
            factorArray.push({
              'Nro.': `${factorIndex + 1}.${sfIndex + 1}`,
              'Factor/Subfactor': sf.name,
              Valor: sf.value || 0,
            });

            if (sf.options) {
              sf.options.forEach((opt, optIndex) => {
                factorArray.push({
                  'Nro.': `${factorIndex + 1}.${sfIndex + 1}.${optIndex + 1}`,
                  'Factor/Subfactor': opt.name,
                  Valor: opt.value || 0,
                });
              });
            }
          });
        }

        return [...acc, ...factorArray];
      }, []);

      evaluationMap.push({
        'Nro.': '',
        'Factor/Subfactor': 'Total',
        Valor: this.pointsMultiplier * 100,
      });

      return [evaluationMap, 'reporte_niveles'];
    },

    exportToExcel(exportConfig) {
      let evaluationStepMap = null;
      let fileName = 'reporte_puntuacion';
      let worksheet = null;
      if (exportConfig.content === 'view') {
        switch (this.currentStep) {
          case 1:
            [evaluationStepMap, fileName] = this.exportFirstStep();
            break;
          case 2:
            [evaluationStepMap, fileName] = this.exportSecondStep();
            break;
          case 3:
            [evaluationStepMap, fileName] = this.exportThirdStep();
            break;
          default:
            [evaluationStepMap, fileName] = this.exportLastStep();
            break;
        }
        worksheet = utils.json_to_sheet(evaluationStepMap);
      } else {
        const filteredArray = this.subfactors
          .filter((subfactor) => subfactor.factorId === exportConfig.content)
          .map((subfactor, index) => ({
            'Nro.': index + 1,
            Factor: exportConfig.content,
            Subfactor: subfactor.name,
            Niveles: subfactor.optionsExample ? subfactor.optionsExample.length : 0,
            ...(subfactor.optionsExample || []).reduce(
              (acc, option, i) => ({
                ...acc,
                [`N. ${i + 1}`]: option,
              }),
              {},
            ),
          }));
        worksheet = utils.json_to_sheet(filteredArray);
        fileName = exportConfig.content;
      }

      const workbook = {
        SheetNames: [fileName],
        Sheets: { [fileName]: worksheet },
      };

      workbook.SheetNames.forEach((sheetName) => {
        const ws = workbook.Sheets[sheetName];
        const range = utils.decode_range(ws['!ref']);

        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = utils.encode_cell({ r: 0, c: C });
          if (!ws[address]) continue;
          ws[address].s = { fill: { fgColor: { rgb: '1A96FC' } } };
        }
      });

      const workbookout = XLSXS.write(workbook, {
        bookType: 'xlsx',
        type: 'binary',
      });
      this.saveBlobAsFile(
        `${fileName}.xlsx`,
        new Blob([this.s2ab(workbookout)], { type: 'application/octet-stream' }),
      );
    },

  },

  async mounted() {
    try {
      this.setIsLoading(true);
      await this.fetchPeriods();
      if (!this.selectedPeriod || !Object.keys(this.selectedPeriod).length) this.setSelectedPeriod(this.periods[0]);
      await this.fetchFactors();
      await this.fetchSubfactors();
    } catch (error) {
      console.log(error);
    } finally {
      this.setIsLoading(false);
    }
  },
};
</script>

<style lang="scss" scoped>
.job-evaluation-process-container {
  position: relative;
  max-height: 100%;
  height: 100%;
  & > *:not(:first-child):not(:last-child) {
    margin-top: 1.5rem;
  }
}

.current-step-container {
  flex-grow: 1;
  overflow-y: hidden;
}

.loading-screen {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: white;
}
</style>
