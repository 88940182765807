<template>
  <footer class="actions-footer-container">
    <Button @click="goToStep(0)" size="small">
      <unicon name="home" fill="currentColor" width="16px" height="16px" />
      Volver al inicio
    </Button>
    <Button @click="goToPreviousStep" size="small">
      <unicon name="arrow-left" fill="currentColor" width="16px" height="16px" />
      Anterior
    </Button>
    <Button variant="primary" :disabled="isDisabled" @click="next" size="small">
      {{ currentStep === 4 ? 'Finalizar y Guardar' : 'Siguiente' }}
      <unicon name="arrow-right" fill="currentColor" width="16px" height="16px" />
    </Button>
  </footer>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import Button from '../../components/buttons/Button.vue';

export default {
  components: { Button },

  props: {
    invalid: Boolean,
  },

  computed: {
    ...mapState('jobEvaluationProcess', {
      currentStep: (state) => state.progress.currentStep,
      hasFinished: (state) => state.progress.hasFinished,
      factors: (state) => state.factors,
    }),

    allFactorsHaveSubfactors() {
      return this.factors.every((factor) => factor.subfactors.length);
    },

    allSubfactorsHaveOptions() {
      const selectedSubfactors = this.factors.reduce(
        (subfactors, factor) => [...subfactors, ...factor.subfactors],
        [],
      );

      return selectedSubfactors.every((sf) => sf.options.length);
    },

    isDisabled() {
      if (this.invalid) return true;

      if (this.currentStep === 2 && !this.allFactorsHaveSubfactors) return true;

      if (this.currentStep === 3 && !this.allSubfactorsHaveOptions) return true;

      return false;
    },
  },

  methods: {
    ...mapMutations('jobEvaluationProcess', ['goToPreviousStep', 'goToNextStep', 'goToStep']),
    ...mapActions('jobEvaluationProcess', ['saveProgress', 'finishProcess']),

    async next() {
      if (this.currentStep >= 4) await this.finishProcess();
      else this.goToNextStep();
    },
  },
};
</script>

<style scoped>
.actions-footer-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  margin-top: auto;
  background-color: white;
}
</style>
