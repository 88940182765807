<template>
  <div class="header-container">
    <div>
      <Breadcrumbs
        :views="[]"
        :currentView="{ label: 'Valorización de puestos', icon: 'suitcase' }"
      />
      <h2>{{ steps[currentStep - 1].name }}</h2>
    </div>

    <div class="content__buttons">
      <Button v-if="currentStep === 3" @click="setIsPointsConfigModalOpen(true)" size="small">
        <unicon name="cog" fill="currentColor" width="16px" height="16px" />
        Configurar Puntos
      </Button>
      <Button @click="onReset" size="small">
        <unicon name="redo" fill="currentColor" width="16px" height="16px" />
        Volver a empezar
      </Button>
      <Button @click="saveProgress" size="small">
        <unicon name="save" fill="currentColor" width="16px" height="16px" />
        Guardar
      </Button>
      <Menu direction="left">
        <template #label>
          <Button type="button" variant="secondary" size="xsmall">
            <unicon
              class="ellipsis"
              name="ellipsis-h"
              fill="currentColor"
              height="16px"
              width="16px"
            />
          </Button>
        </template>
        <template #options>
          <menu-item @click="$emit('export')">
            <unicon name="file-upload" fill="currentColor" height="16px" width="16px"></unicon>
            Exportar
          </menu-item>
        </template>
      </Menu>
    </div>

    <ConfirmDialogue ref="confirmDialogue" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import Button from '@/components/buttons/Button.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';

export default {
  components: {
    Button,
    ConfirmDialogue,
    Breadcrumbs,
    Menu,
    MenuItem,
  },

  computed: {
    ...mapState({
      currentStep: (state) => state.jobEvaluationProcess.progress.currentStep,
      steps: (state) => state.jobEvaluationProcess.steps,
      isLoading: (state) => state.jobEvaluationProcess.isLoading,
      lastFinishedStep: (state) => state.jobEvaluationProcess.progress.lastFinishedStep,
    }),
  },

  methods: {
    ...mapActions('jobEvaluationProcess', ['resetProgress', 'saveProgress']),
    ...mapMutations('jobEvaluationProcess', ['setIsPointsConfigModalOpen']),

    async onReset() {
      try {
        const ok = await this.$refs.confirmDialogue.show({
          title: 'Volver a empezar',
          message:
            '¿Estás seguro que deseas volver a empezar? Todo tu progreso será eliminado. Esta acción no se puede revertir.',
          okButtonText: 'Sí, estoy seguro',
        });

        if (!ok) return;

        await this.resetProgress();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    margin: 0;
    font-size: 2rem;
    font-weight: var(--medium);
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}
</style>
