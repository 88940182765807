<template>
  <div
    class="filter"
    :class="{ 'filter-active': filter.options.some((option) => option.active), disabled }"
  >
    <div class="filter__label">
      <svg v-if="filter.type === 'text'" height="14px" width="14px" fill="currentColor">
        <text x="0" y="11px" fill="currentColor" transform="scale(1)" style="font-weight: 600">
          Aa
        </text>
      </svg>
      <unicon
        v-else-if="filter.type === 'year'"
        name="calender"
        fill="currentColor"
        height="14px"
        width="14px"
      />
      <font-awesome-icon v-else :icon="faCircleChevronDown" size="sm" />
      <span>
        <p>
          {{
            filter.options.filter((option) => option.active).length
              ? `${filter.name}:&nbsp;`
              : `${filter.name}`
          }}
        </p>
        <p>
          {{
            filter.options
              .filter((option) => option.active)
              .map((option) => option.name)
              .join(', ')
          }}
        </p>
      </span>
      <unicon name="angle-down" fill="currentColor" height="13px" width="13px" />
    </div>
  </div>
</template>

<script>
import { faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'FilterItem',

  data() {
    return { faCircleChevronDown };
  },

  props: {
    filter: {
      type: Object,
    },
    type: {
      type: String,
    },
    disabled: {
      default: false,
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.disabled * {
  color: var(--font-color-100) !important;
  background-color: var(--gray-color-100) !important;
  border-color: var(--gray-color-900) !important;
}
.filter {
  .filter__label {
    border: solid 1px var(--gray-color-900);
    border-radius: 5px;
    color: var(--font-color-300);
    height: 35px;
    padding: 0 0.575rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    svg {
      font-size: 11px;
      color: inherit;
    }
    span {
      display: flex;
      align-items: center;
    }
    &:hover {
      background-color: var(--gray-color-100);
    }
    p {
      font-weight: var(--regular);
      font-size: 0.825rem;
      &:nth-child(2) {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 7rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &-active {
    .filter__label {
      border: solid 1px var(--main-color-300);
      border-radius: 5px;
      color: var(--main-color-500);
      background-color: var(--main-color-000);
      p:nth-child(1) {
        font-weight: var(--semi-bold);
      }
      &:hover {
        color: var(--main-color-500);
        background-color: var(--main-color-100);
      }
    }
  }
}

p {
  color: inherit;
}
</style>
