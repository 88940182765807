<template>
  <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <div class="subfactor__content">
        <CustomInput
          :disabled="isLoading"
          label="Nombre:"
          name="name"
          placeholder="Nombre del Subfactor"
          v-model="name"
          rules="required"
        />
        <Textarea
          :disabled="isLoading"
          label="Descripción:"
          name="description"
          placeholder="Descripción del Subfactor"
          v-model="description"
          rules="required"
        />
      </div>

      <div class="modal__buttons">
        <Button :disabled="isLoading" @click="$emit('back')" size="small" variant="text">
          Cancelar
        </Button>
        <Button :disabled="isLoading || invalid" type="submit" variant="primary" size="small">
          Guardar cambios
        </Button>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex';

import { ValidationObserver } from 'vee-validate';

import CustomInput from '@/components/custom/CustomInput.vue';
import Textarea from '@/components/custom/Textarea.vue';
import Button from '@/components/buttons/Button.vue';

export default {
  components: {
    Button,
    CustomInput,
    Textarea,
    ValidationObserver,
  },

  props: {
    isOpen: Boolean,
    subfactor: Object,
  },

  data: () => ({
    name: '',
    description: '',
    isLoading: false,
  }),

  methods: {
    ...mapActions('jobEvaluationProcess', ['updateSubfactor']),

    setInitialValues() {
      this.name = this.subfactor.name;
      this.description = this.subfactor.description;
    },

    async onSubmit() {
      this.isLoading = true;

      try {
        await this.updateSubfactor({
          id: this.subfactor.id,
          name: this.name,
          description: this.description,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        this.$emit('back');
      }
    },
  },

  watch: {},

  mounted() {
    this.setInitialValues();
  },
};
</script>

<style lang="scss" scoped>
.subfactor__content {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding-top: 0.2rem;
  padding-bottom: 1rem;
}
.modal__buttons {
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
}
.button {
  width: 100%;
}
</style>
