<template>
  <SideModal :isOpen="isOpen" @close="close" @back="currentView = 'subfactors'">
    <template #title>{{ factor.name }}: Subfactores</template>
    <template v-if="currentView === 'subfactor'" #title2> {{ factor.name }}: Subfactor </template>
    <template v-else-if="currentView === 'create'" #title2> Nuevo Subfactor </template>
    <template v-else-if="currentView === 'edit'" #title2> Editar Subfactor </template>
    <template #content>
      <div v-if="currentView === 'subfactors'" class="subfactors__view">
        <div class="subfactor__container">
          <SubfactorCard
            class="subfactor__card"
            v-for="(subfactor, index) in filteredSubfactors"
            :key="subfactor.id + index"
            :subfactor="subfactor"
            :index="index"
            @click="
              currentView = 'subfactor';
              currentSubfactor = subfactor;
            "
          />
        </div>
        <div class="subfactor__button">
          <span class="divider"></span>
          <Button
            @click="currentView = 'create'"
            :disabled="false"
            size="medium"
            variant="secondary-text"
          >
            <unicon width="18px" height="18px" name="plus" fill="#B1B0AE"></unicon>
            Crear subfactor
          </Button>
        </div>
      </div>
      <SubfactorView
        v-else-if="currentView === 'subfactor'"
        @edit="currentView = 'edit'"
        @back="currentView = 'subfactors'"
        :subfactor="currentSubfactor"
      />
      <div v-else-if="currentView === 'create'">
        <CreateSubfactor
          @back="currentView = 'subfactors'"
          :factorId="factor.id"
        />
      </div>
      <div v-else-if="currentView === 'edit'">
        <UpdateSubfactor
          @back="currentView = 'subfactors'"
          :subfactor="currentSubfactor"
        />
      </div>
    </template>
  </SideModal>
</template>

<script>
import { mapState } from 'vuex';

import SideModal from '@/components/menu/SideModal.vue';
import Button from '@/components/buttons/Button.vue';
import SubfactorCard from './SubfactorCard.vue';
import CreateSubfactor from './CreateSubfactor.vue';
import UpdateSubfactor from './UpdateSubfactor.vue';
import SubfactorView from './SubfactorView.vue';

export default {
  components: {
    SideModal,
    SubfactorCard,
    Button,
    CreateSubfactor,
    UpdateSubfactor,
    SubfactorView,
  },
  props: {
    isOpen: Boolean,
    factor: Object,
  },

  data: () => ({
    currentSubfactor: {},
    isSubfactorModalOpen: false,
    currentView: 'subfactors',
  }),

  methods: {
    close() {
      this.$emit('close');
      this.currentSubfactor = null;
      this.currentView = 'subfactors';
    },
    openSubfactorModal(subfactorId) {
      this.currentSubfactorId = subfactorId;
      this.isSubfactorModalOpen = true;
    },

    closeSubfactorModal() {
      this.currentSubfactorId = '';
      this.isSubfactorModalOpen = false;
    },
  },

  computed: {
    ...mapState({
      subfactors: (state) => state.jobEvaluationProcess.subfactors,
    }),
    filteredSubfactors() {
      const filteredArray = this.subfactors.filter(
        (subfactor) => subfactor.factorId === this.factor.id,
      );

      const getSubfactor = (id) => this.factor.subfactors.find((sf) => sf.id === id);

      const sortedArray = filteredArray.sort((a, b) => {
        const subfactorA = getSubfactor(a.id);
        const subfactorB = getSubfactor(b.id);

        if (subfactorA && subfactorB) {
          return subfactorB.percentage - subfactorA.percentage;
        }
        if (subfactorA) {
          return -1;
        }
        if (subfactorB) {
          return 1;
        }
        return 0;
      });

      return sortedArray;
    },
  },
};
</script>

<style lang="scss" scoped>
.subfactors__view {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;
}
.subfactor__container {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  padding-right: 0.5rem;
  flex-grow: 1;
  overflow: auto;
}

.subfactor__button {
  background-color: white;
  width: calc(100%);
  padding-top: 1rem;
  button {
    width: 100%;
    justify-content: left;
  }
  .divider {
    width: 100%;
    height: 1.5px;
    background-color: var(--gray-color-200);
    display: flex;
    margin-bottom: 0.375rem;
  }
}
</style>
