<template>
  <div class="periods-modal">
    <Modal size="sm" ref="modal__periods">
      <template #title>Agregar Periodo</template>
      <template #content>
        <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
          <form @submit.prevent="handleSubmit(add)">
            <div class="periods__content">
              <custom-input
                v-model="period.name"
                label="Nombre"
                type="text"
                rules="required"
                icon="calender"
              />
              <div class="radio-buttons">
                <ValidationProvider tag="label" class="radio__label">
                  <input
                    type="radio"
                    name="radio"
                    :value="false"
                    v-model="period.isACopy"
                    @input="
                      period.copyFromId = '';
                      period.isACopy = false;
                    "
                  />
                  <span class="checkmark">
                    <unicon name="notebooks" fill="currentColor" height="25px" width="25px" />
                    <p>Template</p>
                  </span>
                </ValidationProvider>
                <ValidationProvider
                  tag="label"
                  class="radio__label"
                  v-for="item in periods"
                  :key="item.id"
                >
                  <input
                    type="radio"
                    name="radio"
                    :value="item.id"
                    v-model="period.copyFromId"
                    @input="
                      period.copyFromId = item.id;
                      period.isACopy = true;
                    "
                  />
                  <span class="checkmark">
                    <unicon name="calender" fill="currentColor" height="25px" width="25px" />
                    <p>{{ item.name }}</p>
                  </span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form__buttons">
              <Button
                type="submit"
                :disabled="invalid || isAddingLoading"
                size="medium"
                variant="primary"
              >
                {{ isAddingLoading ? 'Agregando...' : 'Agregar periodo' }}</Button
              >
              <Button
                to="/personas-y-puestos/empleados"
                :disabled="invalid || isAddingLoading"
                size="medium"
                variant="text"
                >Cancelar</Button
              >
            </div>
          </form>
        </validation-observer>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import CustomInput from '@/components/custom/CustomInput.vue';
import Button from '@/components/buttons/Button.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'AddEmployee',
  components: {
    ValidationObserver,
    ValidationProvider,
    CustomInput,
    Button,
    Modal,
  },
  data() {
    return {
      isOpen: true,
      period: {
        name: '',
        isACopy: false,
        copyFromId: '',
      },
      isAddingLoading: false,
    };
  },

  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('jobEvaluationProcess', ['addPeriod']),
    async open() {
      this.$refs.modal__periods.open();
    },
    async close() {
      this.$refs.modal__periods.close();
    },
    async add() {
      try {
        this.isAddingLoading = true;

        await this.addPeriod({
          ...(this.period.isACopy
            ? this.periods.find(({ id }) => id === this.period.copyFromId)
            : {}),
          ...this.period,
          index: this.periods.length,
        });
        this.setAlert({
          state: 'success',
          message: 'Periodo agregado',
        });
      } catch (error) {
        console.log(error);
        let message = 'Ocurrió un error, por favor inténtelo nuevamente';
        if (error.code === 'permission-denied') {
          message = 'Acceso denegado. No tienes acceso a estos datos.';
        }
        this.setAlert({
          state: 'error',
          message,
        });
      } finally {
        this.isAddingLoading = false;
        this.period = {
          name: '',
          isACopy: false,
          copyFromId: '',
        };
        this.close();
      }
    },
  },

  computed: {
    ...mapState({
      periods: (state) => state.jobEvaluationProcess.periods,
    }),
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.periods-modal {
  height: 100%;
  display: flex;
  flex-flow: column;
  h2 {
    margin-bottom: 0.15em;
  }

  .periods__content {
    padding: 1.8rem 1.8rem;
    display: flex;
    flex-flow: column;
    gap: 1rem;
  }

  form {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: left;

    & > div:first-child {
      overflow: auto;
    }

    button {
    }
  }

  .form__validation {
    overflow: hidden;
  }

  .form__buttons {
    padding: 1rem 1.5rem;
    display: flex;
    margin-top: 0;
    padding-top: 1rem;
    border-top: solid 2px var(--gray-color-200);
  }
}

.separator {
  background-color: var(--gray-color-200);
  height: 1px;
}

.radio__label {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 5.5rem;
  height: 4.5rem;
  color: var(--font-color-200);
}

.radio__label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  border: solid 2px var(--gray-color-200);
  border-radius: 3px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  p {
    font-size: 0.7rem;
    line-height: 0.7rem;
    font-weight: var(--light);
  }
}

/* On mouse-over, add a grey background color */
.radio__label:hover input:not(:checked) ~ .checkmark {
  background-color: var(--gray-color-100);
}

/* When the radio button is checked, add a blue background */
.radio__label input:checked ~ .checkmark {
  border-color: var(--main-color-500);
  * {
    color: var(--main-color-500);
    font-weight: vaR(--regular);
  }
}
.radio-buttons {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
}
</style>
