<template>
  <li class="table">
    <header class="table__header">
      <div>
        <h5>{{ `${index + 1}.` }}</h5>
        <h5>{{ factor.name }}</h5>
      </div>
      <h5>{{ `${factor.percentage}%` }}</h5>
    </header>

    <div class="table__content">
      <div v-for="subfactor in factor.subfactors" :key="subfactor.id" class="table__row">
        <span>{{ subfactor.name }}</span>
        <UnitInput
          unit="%"
          :name="subfactor.name"
          :rules="{
            required: true,
            between: { min: 1, max: factor.percentage },
            sumShouldEqual: {
              total: factor.percentage,
              values: factor.subfactors.map((sf) => sf.percentage),
            },
          }"
          :value="subfactor.percentage"
          @input="inputHandler(subfactor.id, $event)"
          @blur="updateSelectedSubfactorsOrder(factor.id)"
        />
      </div>
      <Button @click="$emit('onAdd')" :disabled="false" size="medium" variant="secondary-text">
        <unicon width="18px" height="18px" name="plus" fill="#B1B0AE"></unicon>
        Agregar subfactor
      </Button>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';

import UnitInput from '@/components/custom/UnitInput.vue';
import Button from '@/components/buttons/Button.vue';

export default {
  props: {
    factor: Object,
    index: Number,
  },

  components: { UnitInput, Button },

  computed: {
    ...mapState('jobEvaluationProcess', {
      subfactors(state) {
        return state.subfactors.filter(({ factorId }) => factorId === this.factor.id);
      },
    }),
    ...mapGetters('jobEvaluationProcess', ['getSubfactorName']),
  },

  methods: {
    ...mapMutations('jobEvaluationProcess', [
      'updateSubfactorPercentage',
      'updateSelectedSubfactorsOrder',
    ]),

    inputHandler(subfactorId, value) {
      const number = parseInt(value, 10) || 0;
      this.updateSubfactorPercentage({
        factorId: this.factor.id,
        subfactorId,
        percentage: number,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  height: 100%;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    & > div:first-child {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      h5:nth-child(2) {
        padding: 3px 6px;
        border-radius: 3px;
        color: var(--font-color-500);
        background-color: var(--main-color-100);
      }
    }
    h5 {
      color: var(--font-color-100);
    }
  }

  &__content {
    display: flex;
    flex-flow: column;
    gap: 0.5rem;
    background-color: var(--gray-color-000);
    border-radius: 5px;
    padding: 0.4rem;
    flex-grow: 1;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #eaebee;
      border-radius: 0;
    }

    .button {
      width: 100%;
      justify-content: left;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding: 0.7rem 0.625rem;
    color: var(--font-color-700);
    font-size: 0.875rem;
    font-weight: 500;
    background-color: white;
    box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.05) 0px 2px 4px;
    border-radius: 4px;
  }
}
</style>
