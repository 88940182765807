<template>
  <div class="accordion">
    <button
      class="accordion__header"
      type="button"
      :class="{ 'accordion__header--active': isOpen }"
      @click="toggleAccordion"
    >
      <unicon
        class="accordion__icon"
        width="23px"
        height="23px"
        :class="{ 'accordion__icon--active': isOpen }"
        name="angle-down"
      ></unicon>
      <h4>
        <slot name="title"></slot>
      </h4>
    </button>

    <transition name="accordion-content">
      <div v-show="isOpen" class="accordion__content">
        <div class="inner">
          <slot name="content"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Accordion',

  data: () => ({
    isOpen: false,
  }),
  props: {
    launchOpen: {
      default: false,
      type: Boolean,
    },
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },

  mounted() {
    this.isOpen = this.launchOpen;
  },
};
</script>

<style lang="scss" scoped>
.accordion {
  border-radius: 5px;

  &__header {
    width: 100%;
    display: flex;
    gap: 0.55rem;
    align-items: center;
    padding: 0.8rem 0.8rem;
    &:hover {
      background-color: var(--gray-color-000);

      h4 {
        color: var(--main-color-500);
      }
      .unicon {
        fill: var(--main-color-500);
      }
    }

    &--active {
      background-color: var(--gray-color-000);
      border-bottom: var(--gray-color-500) solid 1px;
      border-bottom-width: 1px;
      border-radius: 0;
    }
    h4 {
      color: var(--font-color-500);
      font-size: 0.9rem;
      flex-grow: 1;
    }

    &--active {
      h4 {
        color: var(--main-color-500);
      }
      .unicon {
        fill: var(--main-color-500);
      }
    }
  }

  &__content {
    // max-height: 300px;
    max-width: 100%;
    overflow: hidden;

    .inner {
      // max-height: 300px;
      overflow: hidden;
    }
  }

  &__icon {
    width: 1.75em;
    height: 1.75em;
    display: grid !important;
    place-items: center;
    font-size: 1rem;
    fill: var(--font-color-100);
    border-radius: 50%;
    transition: background-color 150ms, transform 300ms;
    transform: rotate(-90deg);

    &:hover,
    &:focus {
      background-color: var(--gray-color-100);
    }

    &:active {
      background-color: var(--gray-color-200);
    }

    &--active {
      fill: var(--font-color-500);
      transform: rotate(0deg);
    }
  }

  &-content-enter,
  &-content-leave-to {
    max-height: 0;
    opacity: 0;
  }

  &-content-enter-active {
    transition: max-height 1s, opacity 300ms;
  }

  &-content-leave-active {
    transition: max-height 0.5s, opacity 300ms;
  }
}
</style>
